import axios from '@axios'

const state = {
    sensors: []
}

const getters = {
    sensors: state => state.sensors,
    sensorById: (state) => (id) => state.sensors.find(s => s.id == id)
}

const mutations = {
    setSensors(state, sensors) {
        state.sensors = sensors
    }
}

const actions = {
    async fetchSensors({ commit }) {
        if (state.sensors.length > 0) return
        const {
            data: { data },
        } = await axios.get("api/v1/sensors");
        commit('setSensors', data);
    }
}

export default {
    state, getters, actions, mutations
}
