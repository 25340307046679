import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/admin',
      component: () => import('@/views/admin/Index.vue'),
      meta: {
        auth: true,
      },
      children: [
        {
          path: 'sensors',
          component: () => import('@/views/admin/Sensor/Index.vue'),
          children: [{
            path: ':id',
            name: 'admin.sensors.show',
            component: () => import('@/views/admin/Sensor/Show.vue')
          },
          {
            path: '',
            name: 'admin.sensors',
            component: () => import('@/views/admin/Sensor/List.vue')
          }
          ]
        },
        {
          name: 'admin.dashboard',
          path: '',
          component: () => import('@/views/admin/Dashboard.vue'),
        },
      ]
    },
    {
      path: '/auth',
      meta: {
        auth: false,
      },
      component: () => import('@/views/auth/Index.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/auth/Login.vue')
        }
      ]
    },
    {
      path: '/error',

      component: () => import('@/views/error/Index.vue'),
      children: [
        {
          path: '404',
          name: 'notfound',

          component: () => import('@/views/error/Error404.vue'),

        },
      ]
    },

    {
      path: '/',
      component: () => import('@/views/dashboard/Index.vue'),
      children: [
        {
          path: ':id',
          name: 'sensor',

          component: () => import('@/views/dashboard/Home.vue')
        },
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/views/dashboard/Home.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: 'dashboard',
    },
  ],
})

Vue.router = router;

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
